<template>
    <div v-if="table" class="z-100">
            <div v-if="table.attributes.total > 0 || table.attributes.hourly_rate_item_id" class="content-container radius-anchor p-5 shadow-sm sm:mt-0 mb-5 bg-shoppingcarttotals">            <div class="">
                <div class="mb-4">
                   <div class="mb-2 text-red-500"> {{ $t('shoppingcart.table_onaccount_description') }} </div>
                   <div>
                       <strong> {{ $t('shoppingcart.total_payable') }}: </strong>
                       {{ $n(table.attributes.total, currency) }}
                   </div>
                </div>
                <div @click="toCheckout()" class="w-full inline-flex items-center btn btn-primary justify-center w-full rounded-sm btn btn-primary-outline  px-4 py-2 text-base leading-6 font-medium shadow-sm focus:outline-none">
                    <span class="ml-2 font-bold">{{ $t('tables.button.payment') }}</span>
                </div>
            </div>
        </div>
        <modal-hourly-rate-confirm ref="hourlyrate" />
    </div>
</template>

<script>

// libs
import * as _table from '../lib/table';
import * as _transaction from '../lib/transaction';
import * as _state from "../lib/state";
import ItemHelper from '../mixins/item.helper.js';
import ModalHourlyRateConfirm from './ModalHourlyRateConfirm.vue';

export default {
    name: 'TablePaymentButton',
    mixins: [ItemHelper],
    components: {
        ModalHourlyRateConfirm,
    },
    methods: {
        async toCheckout() {
            if (this.table.attributes.hourly_rate_item_id) {

                this.$refs.hourlyrate.open();
                return;
            }

            this.$router.push({ name: 'checkout.table', params: {location_slug: this.$route.params.location_slug}});
        },
    },

    computed: {
        table() {
            return _table.get();
        },
        config () {
            return _state.get('config/getConfig');
        },
        currency (){
            return this.config.settings.currency;
        }
    },
}
</script>
